import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="sobre" className="about_section">
      <div className="container">
        <h1 className="about_title">{t('sobre_mrq_sistemas')}</h1>
        <div className="about_content">
          <h2>{t('quem_somos_titulo')}</h2>
          <p>{t('quem_somos_descricao')}</p>
          
          <h2>{t('nossa_missao_titulo')}</h2>
          <p>{t('nossa_missao_descricao')}</p>
        </div>
      </div>
    </section>
  );
};

export default About;
