import React, { useEffect, useState } from 'react';
import './styles.css';

const CustomFormComponent = ({ texts, formFields, onSubmit, values, onChange, children, textRotationInterval = 3000 }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length); 
    }, textRotationInterval); 

    return () => clearInterval(interval);
  }, [texts.length, textRotationInterval]);

  return (
    <div className="custom_form_container">
      <div className="text_area">
        <h2 className="rotating_text">{texts[currentTextIndex]}</h2>
      </div>
      <div className="form_area">
        <form onSubmit={onSubmit} role="form">
          {formFields.map((field, index) => (
            <div className="form_group" key={index}>
              <label htmlFor={field.name}>{field.label}</label>
              {field.type === 'textarea' ? (
                <textarea
                  id={field.name}
                  name={field.name}
                  required={field.required}
                  placeholder={field.placeholder}
                  value={values[field.name] || ''}
                  onChange={onChange}
                />
              ) : (
                <input
                  id={field.name}
                  type={field.type}
                  name={field.name}
                  required={field.required}
                  placeholder={field.placeholder}
                  value={values[field.name] || ''}
                  onChange={onChange}
                />
              )}
            </div>
          ))}
          {children}
        </form>
      </div>
    </div>
  );
};

export default CustomFormComponent;
