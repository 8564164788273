import React from 'react';
import HeroSection from '../../components/hero-section';

const Home = () => {
  return (
    <HeroSection />
  );
};

export default Home;
