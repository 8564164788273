import Button from '@mrqsistemas/button';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language_selector">
      <Button onClick={() => changeLanguage('pt-BR')}>Português</Button>
      <Button onClick={() => changeLanguage('en')}>English</Button>
    </div>
  );
};

export default LanguageSelector;