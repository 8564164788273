import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../language-selector';
import './styles.css';

const Navbar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const scrollToSection = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (id === 'inicio') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top mb-0">
      <div className="container">
        <a className="navbar-brand" href="#inicio" onClick={(e) => scrollToSection(e, 'inicio')}>
          MrqSistemas
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarNav"
          aria-expanded={isOpen}
          aria-label={t('toggle_navigation')}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <a className="nav-link" href="#inicio" onClick={(e) => scrollToSection(e, 'inicio')}>
                {t('inicio')}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#servicos" onClick={(e) => scrollToSection(e, 'servicos')}>
                {t('servicos')}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#sobre" onClick={(e) => scrollToSection(e, 'sobre')}>
                {t('sobre')}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contato" onClick={(e) => scrollToSection(e, 'contato')}>
                {t('contato')}
              </a>
            </li>
          </ul>
          <LanguageSelector />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
