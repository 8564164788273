import { library } from '@fortawesome/fontawesome-svg-core';
import { faCloud, faComments, faMobileAlt, faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Adicione os ícones à biblioteca
library.add(faCloud, faMobileAlt, faComments, faPlug);

const Servicos = () => {
  const { t } = useTranslation();

  const servicos = [
    {
      titulo: t('saas_b2c_personalizado'),
      descricao: t('descricao_saas'),
      icone: faCloud
    },
    {
      titulo: t('aplicativos_web_mobile'),
      descricao: t('descricao_aplicativos'),
      icone: faMobileAlt
    },
    {
      titulo: t('suporte_consumidor'),
      descricao: t('descricao_suporte'),
      icone: faComments
    },
    {
      titulo: t('integracao_apis'),
      descricao: t('descricao_integracao'),
      icone: faPlug
    }
  ];


  return (
    <section id="servicos" className="py-5 mt-5">
      <div className="container">
        <h2 className="text-center mb-4">{t('nossos_servicos')}</h2>
          <div className="servicos_grid">
            {servicos.map((servico, index) => (
              <div key={index} className="servico_card">
                <div className="servico_icon">
                  <FontAwesomeIcon icon={servico.icone} />
                </div>
                <h3 className="servico_titulo">{servico.titulo}</h3>
                <p className="servico_descricao">{servico.descricao}</p>
              </div>
            ))}
          </div>
      </div>
    </section>
  );
};

export default Servicos;
