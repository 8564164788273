import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const whatsappNumber = '+5521971737389';
  const whatsappLink = `https://wa.me/${whatsappNumber}`;
  const emailAddress = 'contato@mrqsistemas.com.br';
  const emailLink = `mailto:${emailAddress}`;

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3>{t('footer_titulo')}</h3>
            <p>{t('footer_descricao')}</p>
          </div>
          <div className="col-md-6">
            <h3>{t('footer_contato')}</h3>
            <ul className="list-unstyled">
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                <a href={emailLink}>{emailAddress}</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faWhatsapp} className="me-2" />
                <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                  +55 21 97173-7389
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12 text-center">
            <p>{t('footer_direitos_reservados', { year: currentYear })}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
