import Button from '@mrqsistemas/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-parallax';
import './styles.css';

const HeroSection = () => {
  const { t } = useTranslation();

  const scrollToServices = () => {
    const servicesSection = document.getElementById('servicos');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Parallax bgImage="banner.webp" strength={500} className="parallax-container" id="inicio">
      <div className="hero-content">
        <div className="text-container">
          <h1>{t('bem_vindo_mrq_sistemas')}</h1>
          <p>{t('solucoes_inovadoras_saas')}</p>
          <Button className="cta_button" onClick={scrollToServices} aria-label={t('saiba_mais')}>{t('saiba_mais')}</Button>
        </div>
      </div>
    </Parallax>
  );
};

export default HeroSection;
