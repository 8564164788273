import Button from '@mrqsistemas/button';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';

const CookieNotice = () => {
  const [showNotice, setShowNotice] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const noticeSeen = Cookies.get('cookieNoticeSeen');
    if (!noticeSeen) {
      setShowNotice(true);
    }
  }, []);

  const handleClose = () => {
    Cookies.set('cookieNoticeSeen', 'true', { expires: 365 });
    setShowNotice(false);
  };

  if (!showNotice) return null;

  return (
    <div className="cookie-notice">
      <p className="cookie-notice-text">{t('aviso_cookies')}</p>
      <Button onClick={handleClose} className="cookie-notice-button">{t('fechar')}</Button>
    </div>
  );
};

export default CookieNotice;
