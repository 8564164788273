import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from '../locales/en/translation.json';
import translation_pt from '../locales/pt-BR/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
const resources = {
  pt: {
    translation: translation_pt
  },
  en: {
    translation: translation_en
  }
};

i18n
  .use(LanguageDetector) // Detect language from localStorage
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'pt',
    detection: {
      order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
