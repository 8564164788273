import React from 'react';
import About from '../../pages/about';
import Contact from '../../pages/contact';
import Home from '../../pages/home';
import Services from '../../pages/my-services';
import Footer from '../footer';
import './styles.css';

const Main = () => {
  return (
    <main>
      <Home />
      <Services />
      <About />
      <Contact />
      <Footer />
    </main>
  );
};

export default Main;
