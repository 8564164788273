import Button from '@mrqsistemas/button'; // Importando o botão personalizado
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieNotice from '../../components/cookie-consent';
import CustomFormComponent from '../../components/custom-form';
import config from '../../config.js';
import './styles.css';

const Contato = () => {
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({
    nome: '',
    email: '',
    mensagem: ''
  });

  useEffect(() => {
    const submitted = Cookies.get('formSubmitted');
    if (submitted === 'true') {
      setFormSubmitted(true);
      setFormValues({ nome: '', email: '', mensagem: '' });
    }
  }, []);

  const handleInputChange = (e) => {
    if (formSubmitted) return;
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formSubmitted) return;

    try {
      const response = await fetch(`${config.apiUrl}/process_contact.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      });
      const data = await response.json();
      console.log('Resposta do servidor:', data);
      
      setFormSubmitted(true);
      Cookies.set('formSubmitted', 'true', { expires: 365 });
      setFormValues({ nome: '', email: '', mensagem: '' });
      toast.success(t('mensagem_sucesso'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
      toast.error(t('mensagem_erro'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const formFields = [
    { name: 'nome', label: t('nome'), type: 'text', required: true, placeholder: t('placeholder_nome') },
    { name: 'email', label: t('email'), type: 'email', required: true, placeholder: t('placeholder_email') },
    { name: 'mensagem', label: t('mensagem'), type: 'textarea', required: true, placeholder: t('placeholder_mensagem') }
  ];

  const rotatingTexts = [
    t('rotating_text_1'),
    t('rotating_text_2'),
    t('rotating_text_3'),
    t('rotating_text_4')
  ];

  return (
    <section id="contato" className="contato-wrapper">
      <div className={`contato-container ${formSubmitted ? 'disabled' : ''}`}>
        <div className="contato-header">
          <h2>{t('entre_em_contato')}</h2>
          <p>{t('descricao_contato')}</p>
        </div>
          <CustomFormComponent
            texts={rotatingTexts}
            formFields={formFields}
            onSubmit={handleSubmit}
            values={formValues}
            onChange={handleInputChange}
          >
          <Button type="submit" className="cta_button">
            {formSubmitted ? t('mensagem_enviada') : t('enviar')}
          </Button>
        </CustomFormComponent>
      </div>
      <ToastContainer />
      <CookieNotice />
    </section>
  );
};

export default Contato;
